import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState } from 'react';
import excelClipboardCopy from '../../hooks/excelClipboardCopy';
import BasicButton from '../NPSportal/basicButton';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const EditableGrid = ({ columns, data, setSaveData, title, close }) => {
  const gridOptions = {
    rowData: data,
    rowSelection: 'multiple',
    onCellValueChanged: (e) => onCellChanged(e),
    animateRows: true,
    editType: 'fullRow',
    columnDefs: columns.map((column) => {
      return Object.fromEntries(
        Object.entries(column).map(([key, value]) => [
          key,
          value === 'true' ? true
          : value === 'false' ? false
          : value,
        ])
      );
    }),
  };

  const [rowData, setRowData] = useState(gridOptions.rowData);
  const [modifiedRows, setModifiedRows] = useState([]);
  const gridRef = useRef(null);
  const [api, setApi] = useState(null); // State for storing the grid API
  const copyToClipboard = excelClipboardCopy();

  const duplicateSelectedRows = () => {
    const selectedNodes = api.getSelectedNodes();
    if (selectedNodes.length === 0) {
      return;
    }

    const duplicatedRows = selectedNodes.map((node) => {
      const selectedData = node.data;
      return { ...selectedData, id: generateUniqueId() };
    });

    const newRowData = [...rowData];
    selectedNodes.forEach((node, index) => {
      newRowData.splice(node.rowIndex + index + 1, 0, duplicatedRows[index]);
    });

    setRowData(newRowData);

    api.setRowData(newRowData);

    setModifiedRows((prevModifiedRows) => [...prevModifiedRows, ...duplicatedRows]);
  };

  const onCellChanged = (event) => {
    const updatedRow = event.data;
    setModifiedRows((prevModifiedRows) => {
      const rowExists = prevModifiedRows.find((row) => row.id === updatedRow.id);
      if (rowExists) {
        return prevModifiedRows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
      }

      return [
        ...prevModifiedRows,
        { ...updatedRow, id: updatedRow.id || updatedRow.unique_id || generateUniqueId() },
      ];
    });
  };

  const copySelectedRowsToClipboard = () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();

    const allColumns = gridRef.current.columnApi.getAllColumns();
    const visibleColumns = allColumns.filter((col) => col.getColDef().hide !== true);

    const columnOrder = visibleColumns.map((col) => col.getColId());
    const selectedData = selectedNodes.map((node) => node.data);

    const textToCopy = selectedData
      .map((row) =>
        columnOrder
          .map((colId) => {
            const value = row[colId];

            return `"${value}"`;
          })
          .join('\t')
      )
      .join('\r\n');

    copyToClipboard(textToCopy);
  };

  function generateUniqueId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 8;
    let uniqueId = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueId += characters[randomIndex];
    }

    return uniqueId;
  }

  function saveView() {
    setSaveData(modifiedRows);
  }

  const exportToCSV = () => {
    api.exportDataAsCsv({
      skipHeader: false,
      columnGroups: true,
      allColumns: false,
    });
  };

  const onGridReady = (params) => {
    setApi(params.api);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        copySelectedRowsToClipboard();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    // Cleanup function to destroy the grid API on unmount
    return () => {
      if (api) {
        api.destroy(); // Destroy the grid instance
      }
    };
  }, [api]);

  return (
    <>
      <div
        role='button'
        aria-label='Back to previous page'
        tabIndex={0}
        onClick={() => {
          close();
        }}
        className='laminates-back-button'
        style={{ left: '11%', top: '14rem', color: 'var(--darkblue)' }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            close();
          }
        }}
      >
        {'< Back'}
      </div>
      <div style={{ width: '80%', margin: 'auto' }}>
        <div className={`in-stock-table-header`}>
          <div
            style={{ alignItems: 'end' }}
            className='basic-flex'
          >
            <h2>{title}</h2>
          </div>
          <div className='basic-flex'>
            <BasicButton
              type={'basic-white-button'}
              text={'Duplicate Rows'}
              onClick={duplicateSelectedRows}
            />
            <BasicButton
              text={'Export'}
              type={'basic-white-button'}
              onClick={() => exportToCSV()}
            />
            <BasicButton
              text={'Save Data'}
              type={'basic-green-button'}
              onClick={saveView}
            />
          </div>
        </div>

        <div
          className='ag-theme-alpine'
          style={{ height: 650, margin: 'auto' }}
        >
          <AgGridReact
            ref={gridRef}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </>
  );
};

export default EditableGrid;
