import React from 'react';
import { useNavigate } from 'react-router-dom';
import useGeneralImages from '../../hooks/useGeneralImages';

const Inspire = () => {
  const navigate = useNavigate();
  const { generalImages } = useGeneralImages();
  const inspire = generalImages.find((i) => i.publicId === 'Inspire_ub3gce')?.imageLink;
  const inspire1 = generalImages.find((i) => i.publicId === 'Inspire_1_t3xegp')?.imageLink;
  const inspire2 = generalImages.find((i) => i.publicId === 'Inspire_2_terxff')?.imageLink;
  const inspire3 = generalImages.find((i) => i.publicId === 'Inspire_3_u2wlec')?.imageLink;
  const inspire4 = generalImages.find((i) => i.publicId === 'Inspire_4_ieo3ic')?.imageLink;
  return (
    <>
      <div className='inspires'>
        <div className='inner-inspires'>
          <h1>Get Inspired!</h1>
          <h3>See our portfolio of environments to inspire yourself</h3>
          <div className='images'>
            <div className='first-column'>
              <img
                src={inspire4}
                alt='Science Lab'
              />
              <img
                src={inspire3}
                alt='Cafeteria'
              />
            </div>
            <div className='second-column'>
              <img
                src={inspire2}
                alt='Classroom'
              />
            </div>
            <div className='third-column'>
              <img
                src={inspire1}
                alt='Music Room'
              />
              <img
                src={inspire}
                alt='Cafeteria'
              />
            </div>
          </div>
          <button
            onClick={() => {
              navigate('/environments');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate('/environments');
              }
            }}
          >
            View More
          </button>
        </div>
      </div>
      <div style={{ height: '967px' }}></div>
    </>
  );
};

export default Inspire;
